import _helper from "../helper";
import _ActionSet from "../ActionSet";
import _types from "../types";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Toast = exports.primaryAction = exports.clear = exports.show = exports.Action = void 0;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
var types_1 = _types;
var Action;
(function (Action) {
  Action["SHOW"] = "APP::TOAST::SHOW";
  Action["CLEAR"] = "APP::TOAST::CLEAR";
  Action["ACTION"] = "APP::TOAST::ACTION";
})(Action = exports.Action || (exports.Action = {}));
function show(toastMessage) {
  return helper_1.actionWrapper({
    group: types_1.Group.Toast,
    payload: toastMessage,
    type: Action.SHOW
  });
}
exports.show = show;
function clear(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.Toast,
    type: Action.CLEAR
  });
}
exports.clear = clear;
function primaryAction(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.Toast,
    type: Action.ACTION
  });
}
exports.primaryAction = primaryAction;
var Toast = /** @class */function (_super) {
  __extends(Toast, _super);
  function Toast(app, options) {
    var _this = _super.call(this, app, types_1.Group.Toast, types_1.Group.Toast) || this;
    _this.message = "";
    _this.duration = 5000;
    _this.set(options);
    return _this;
  }
  Object.defineProperty(Toast.prototype, "options", {
    get: function () {
      var _a;
      return {
        duration: this.duration,
        isError: this.isError,
        message: this.message,
        action: ((_a = this.action) === null || _a === void 0 ? void 0 : _a.content) ? {
          content: this.action.content
        } : undefined
      };
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Toast.prototype, "payload", {
    get: function () {
      return __assign({
        id: this.id
      }, this.options);
    },
    enumerable: false,
    configurable: true
  });
  Toast.prototype.set = function (options) {
    var mergedOptions = helper_1.getMergedProps(this.options, options);
    var message = mergedOptions.message,
      duration = mergedOptions.duration,
      isError = mergedOptions.isError,
      action = mergedOptions.action;
    this.message = message;
    this.duration = duration;
    this.isError = isError;
    this.action = (action === null || action === void 0 ? void 0 : action.content) ? {
      content: action.content || ""
    } : undefined;
    return this;
  };
  Toast.prototype.dispatch = function (action) {
    switch (action) {
      case Action.SHOW:
        {
          var openAction = show(this.payload);
          this.app.dispatch(openAction);
          break;
        }
      case Action.CLEAR:
        this.app.dispatch(clear({
          id: this.id
        }));
        break;
      case Action.ACTION:
        this.app.dispatch(primaryAction({
          id: this.id
        }));
        break;
    }
    return this;
  };
  return Toast;
}(ActionSet_1.ActionSet);
exports.Toast = Toast;
export default exports;
export const __esModule = exports.__esModule;
const _Toast = exports.Toast,
  _primaryAction = exports.primaryAction,
  _clear = exports.clear,
  _show = exports.show,
  _Action = exports.Action;
export { _Toast as Toast, _primaryAction as primaryAction, _clear as clear, _show as show, _Action as Action };